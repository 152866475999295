import { Flex, IconButton } from '@chakra-ui/react';
import React, { useState } from 'react';
import Container from './Container';
import { HamburgerIcon } from '@chakra-ui/icons';
import MobileMenu from './MobileMenu';
import HeaderLogo from '../Common/HeaderLogo';
import HeaderMenu from '../Common/HeaderMenu';
import HeaderSearchButton from '../Common/HeaderSearchButton';
import { HeaderProps, HeaderStyle } from './Header';
import useHeaderHeight from '../../hooks/useHeaderHeight';

const EbikeAtlasHeader: React.FC<HeaderProps> = ({
  children,
  menu,
  headerStyle,
  ...props
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const height = useHeaderHeight();

  const bg =
    headerStyle === HeaderStyle.invisible ? 'transparent' : 'headerColor';

  return (
    <Flex
      bg={bg}
      color="#fff"
      as="header"
      height={height || '80px'}
      alignItems="stretch"
      pos="relative"
      zIndex={8}
      flexDirection="column"
      {...props}
    >
      <MobileMenu
        menu={menu}
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
      <Container height="100%">
        <Flex height="100%">
          <Flex alignItems="center" paddingRight={12}>
            <HeaderLogo maxWidth="150px" />
          </Flex>
          <Flex
            ml="auto"
            justifyContent="center"
            alignItems="center"
            display={{
              base: 'flex',
              md: 'none',
            }}
          >
            <IconButton
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              variant="unstyled"
              aria-label="Menu"
              fontSize="2xl"
              icon={<HamburgerIcon />}
            />
          </Flex>
          <Flex
            flex={{
              base: 0,
              md: 1,
            }}
            height="100%"
            alignItems="stretch"
          >
            <HeaderMenu
              menu={menu}
              useInvisibleHeader
              display={{
                base: 'none',
                md: 'flex',
              }}
            />
            <HeaderSearchButton />
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
};

export default EbikeAtlasHeader;
